(function ($) {
  $(document).ready(function () {

    $('a[href^="#"]:not(a.menu-trigger,#back_to_top a)').on('click', function (e) {

      var $href = $(this).attr('href'),
        $tgt = $($href == "#" || $href == "" ? 'html' : $href),
        $pos = $tgt.offset().top,
        $correctH = 65;
      
        if(window.innerWidth > 1000){
          
          if($('.navigation__fix__wrap').hasClass('fixed')){
            $correctH = 90;
          }else{
            $correctH = 200;
          }
          
        }
      
      $("html, body").animate({
        scrollTop: $pos - $correctH
      }, 500);
      e.preventDefault();

    });

    $('.slider').slick({
      arrows: false,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      centerMode:true,
      centerPadding: '5%',
      responsive: [
        {
          breakpoint: 768, //767px以下のサイズに適用
          settings: {
            centerMode: false,
            centerPadding: 0 ,
          }
        }
      ]
      
    });


    // gmap iframe
    $('.gmap').click(function () {
      $('.gmap iframe').css("pointer-events", "auto");
    });

  });




  
})(jQuery);



window.addEventListener('DOMContentLoaded', function () {
  
  //set noopener
  
  var $links = document.querySelectorAll('a[target="_blank"]');
  [].forEach.call($links,function(link){
    
    if(link.hasAttribute('rel') === false){
      link.setAttribute('rel','noopener');
    }

  })
  
  
  



  //hmb
  
  function gnavi(){
    
    const toggle = document.getElementById('toggle');
    
    if(!toggle){
      return;
    }
    
    
    const menu = document.getElementById('menu');
    const gnav = document.querySelector('#navigation__fix__wrap');
    const menuTrigger = document.querySelector('.menu-trigger');
    const menuLists = document.querySelectorAll('#menu li a');
    const body = document.querySelector('body');
    const menuWrap = document.querySelector('#menu .menu__wrap');

    const changeList = [toggle,menu,gnav,menuTrigger,body]


    toggle.addEventListener('click', function (e) {
      menu.style.transition = "all 0.5s ease-in-out";
      e.preventDefault();

      changeList.forEach((elm)=>{
        console.log(elm)
        elm.classList.toggle('menu-open')
      })

      /*menu.classList.toggle('menu_open')
    toggle.classList.toggle('active');
    body.classList.toggle('menuopen');
    gnav.classList.toggle('active');
    menuTrigger.classList.toggle('triggeractive');*/
    });

    window.addEventListener('resize',function(){
      if(window.innerWidth >= 970){
        menu.style.transition = "none";
        changeList.forEach((elm)=>{
          elm.classList.remove('menu-open')
        })
      }
    });

    menu.addEventListener('click',function(e){

      if(window.innerWidth < 970){
        e.preventDefault();
        changeList.forEach((elm)=>{
          elm.classList.remove('menu-open')
        })
      }
    });
    
    
    menuWrap.addEventListener('click', function(e){
      e.stopPropagation();
    })
    
  }
  
  gnavi();
  
  objectFitImages('.ofi');

  
});






//fixed

function headerFix(){
  
  const navigationFixWrap = document.querySelector('#navigation__fix__wrap');
  let scr;
  let tgtPosition;
  
  function addFixed(){
    
    scr = window.pageYOffset;
    tgtPosition = 200;
    if(window.innerWidth >= 768){
      
      if(scr > tgtPosition){
        navigationFixWrap.classList.add('fixed');
      }else{
        navigationFixWrap.classList.remove('fixed');
      }
      
    }
  }
  addFixed();
  
  window.addEventListener('scroll',addFixed);
  window.addEventListener('load',addFixed);
  

}

headerFix();




function parallax(){
  
  const parallax = document.querySelectorAll('.parallax,.ofi');
  
  const options = {
    root: null,
    rootMargin: '-100px 0px',
    transhold: 0
  }
  
  const observer = new IntersectionObserver(doWhenIntersect, options);
  
  [...parallax].forEach((elm)=>{
    observer.observe(elm);
  })
  
  function doWhenIntersect(entries) {
    entries.forEach((entry)=>{
      if(entry.isIntersecting){
        entry.target.classList.add('parallaxActive')
      }
    });
  }
  
  
}

parallax();